import { useState } from "react";
import ContentArea from "./components/ContentArea";
import Header from "./components/Header";
import { initPlayer, TabContext } from "./components/TabContext";
import { useParams } from "react-router-dom";
import { useBaseQuery } from "api/core/useBaseQuery";
import { PLAYER_WALLET_API_URLS, PLAYERS_API_URLS } from "config/apiUrls";

type WalletData = {
  curency: object;
  playerId: string;
  total: number;
};
function Details() {
  const { id } = useParams();
  const { data: walletData } = useBaseQuery<WalletData>({
    //@ts-ignore
    endpoint: PLAYER_WALLET_API_URLS.GET_PLAYER_WALLET(id),
    enabled: !!id,
  });

  const [activeTab, setActiveTab] = useState("Overview");
  const { data: player } = useBaseQuery<any>({
    //@ts-ignore
    endpoint: PLAYERS_API_URLS.GET_PLAYER_BY_ID(id),
  });
  return (
    <div className="flex flex-col gap-[16px]">
      <TabContext.Provider
        value={{
          activeTab,
          setActiveTab,
          walletData,
          player: { ...initPlayer, ...player },
        }}
      >
        <Header />
        <ContentArea />
      </TabContext.Provider>
    </div>
  );
}

export default Details;
