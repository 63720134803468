import axiosInstance from "config/axiosInstance";
import { storageHelper } from "utils/storage/StorageHelper";

const authService = {
  login: async (userId: string, password: string) => {
    try {
      const data = {
        email: userId,
        password: password,
      };

      const response = await axiosInstance.post("auth/login", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const token = response.data.token;
      if (token) {
        storageHelper.setStoreWithEncryption("token", token);
      }

      return response;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  },

  checkAuthStatus: () => {
    const token = storageHelper.getStoreWithDecryption("token");

    return token ? true : false;
  },

  logout: async () => {
    try {
      localStorage.removeItem("token");

      return true;
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  },
};

export default authService;
