import React, { useContext, useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BSelect from "@components/core-components/select";
import PInput from "@components/core-components/input";
import Modal from "@components/core-components/modal";
import AddCurrencyModalContent from "./AddCurrencyModalContent";
import { TabContext } from "pages/players/details/components/TabContext";
import axios from "axios";
import { DEPOSIT_API_URLS } from "config/apiUrls";

// Validation schema
const schema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .min(1, "Amount must be greater than 0"),
});

interface IFormInput {
  amount: number;
}

const DepositForm: React.FC = () => {
  const { walletData } = useContext(TabContext);

  const [selectedOption, setSelectedOption] = useState("EUR");
  const [balance, setBalance] = useState<number>(0);
  const [walletId, setWalletId] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const selectedWallet = walletData?.find(
      (wallet: any) => wallet.currency.shortCut === selectedOption
    );
    if (selectedWallet) {
      setBalance(selectedWallet.total);
      setWalletId(selectedWallet._id);
    }
  }, [selectedOption, walletData]);

  const options = walletData?.map((wallet: any) => ({
    value: wallet.currency.shortCut,
    label: wallet.currency.shortCut,
  }));

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const model = {
      amount: data.amount,
    };

    const { url } = DEPOSIT_API_URLS.DEPOSIT(walletId);

    try {
      const result = await axios.put(url, model);
      console.log(result.data);
    } catch (error) {
      console.error("Error making deposit:", error);
    }
  };
  return (
    <form
      className="flex flex-col pt-6 pb-9 mb-52 shadow-[0px_2px_6px_rgba(0,0,0,0.1)] bg-white"
      onSubmit={handleSubmit(onSubmit)}
    >
      <main className="flex flex-col items-start px-6 w-full text-slate-600 max-md:px-5 max-md:max-w-full">
        <div className="h-[42px] flex flex-row items-center w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Balance
          </span>
          <PInput
            disabled
            type="number"
            value={balance.toFixed()}
            className="h-full w-[437px] font-bold"
            wrapperClassNames="h-full"
          />
        </div>
        <hr className="mt-1 border-gray-300 w-full max-w-[406px]" />
        <div className="h-[42px] flex flex-row items-center w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Player Wallet
          </span>
          <div className="h-full w-[437px] flex flex-row items-center gap-3">
            <BSelect
              className="h-full w-[319px]"
              options={options}
              value={selectedOption}
              placeholder="Select an Option"
              onChange={(e) => setSelectedOption(e.target.value)}
            />
            <button
              onClick={handleOpenModal}
              className={`flex h-full items-center justify-center bg-primary-light w-[106px] text-[13px] hover:bg-primary-light-hover text-primary px-2 py-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-bold `}
            >
              <span>+ Add Wallet</span>
            </button>
          </div>
        </div>
        <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[406px]" />
        <div className="h-[42px] flex flex-row items-center  w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Amount
          </span>
          <PInput
            id="amount"
            type="number"
            register={register}
            className="h-full w-[437px] font-bold"
            wrapperClassNames="h-full"
            placeholder="Enter amount"
            // error={errors.amount}
          />
        </div>

        <hr className="mt-1 border-gray-300 w-full max-w-[406px]" />
        {errors.amount && (
          <span className="text-red-500 text-sm mt-1">
            {errors.amount.message}
          </span>
        )}
        <footer className="mt-5 w-full max-w-[855px] h-[42px] flex flex-row justify-end">
          <button
            onClick={handleSubmit(onSubmit)}
            type="button"
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-indigo-600 w-[139px] text-body-reg-13 h-full ml-3"
          >
            Deposit
          </button>
        </footer>
      </main>
      <Modal
        isOpen={isModalOpen}
        title="Add Currency"
        onClose={handleCloseModal}
        content={<AddCurrencyModalContent />}
        footer={
          <>
            <button
              type="button"
              className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg hover:bg-gray-300 text-body-reg-13 font-semibold w-[140px] h-[40px]"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 w-[140px] h-[40px] font-semibold text-body-reg-13 ml-3"
            >
              Save
            </button>
          </>
        }
      />
    </form>
  );
};

export default DepositForm;
