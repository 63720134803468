import React from "react";
import PlayerInfo from "./PlayerInfo";
import FinancialOverview from "./FinancialOverview";
import NavigationTabs from "./NavigationTabs";

export const PlayerProfile: React.FC = () => {
  return (
    <main className="flex flex-col px-8 pt-8 pb-4 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex flex-row justify-between items-start w-full max-md:max-w-full">
          <PlayerInfo />
          <FinancialOverview />
        </div>
        <NavigationTabs />
      </div>
    </main>
  );
};

export default PlayerProfile;
