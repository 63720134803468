import { ReactElement, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import logo from "../../assets/images/logo.png";
import cx from "classnames";
import BreadCrumb from "./BreadCrumb";
import UserProfile from "./UserProfile";
import LanguageSelect from "./LanguageSelect";
import { useTranslate } from "../../utils/locales/use-locales";
import Icon from "@components/core-components/icon";
import QuickAccess from "pages/players/list/components/QuickAccess";

export default function DashboardLayout({ children }: any) {
  const { t } = useTranslate();
  const [navColapssed, setNavCollapsed] = useState<boolean>(true);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const location = useLocation();
  const { pathname } = location;

  const handleDisclosureToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const hoverTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseEnter = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    if (navColapssed) {
      setNavCollapsed(false);
    }
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      if (!navColapssed) {
        setNavCollapsed(true);
      }
    }, 200);
  };
  const sidebarRef = useRef<HTMLDivElement>(null);
  interface NavigationItem {
    name: string;
    href?: string;
    icon: ReactElement;
    current: boolean;
    children?: { name: string; href?: string }[];
  }
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setNavCollapsed(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);
  const navigation: NavigationItem[] = [
    {
      name: t("menuItems.dashboard"),
      href: "#",
      icon: <Icon iconName="categoryIcon2" className="h-6 w-6 shrink-0" />,
      current: true,
    },
    {
      name: t("menuItems.players"),
      icon: <Icon iconName="players" className="h-6 w-6 shrink-0" />,
      current: false,
      children: [{ name: t("menuItems.list"), href: "/players/list" }],
    },
    {
      name: t("menuItems.reports"),
      icon: <Icon iconName="report" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.payment"),
      href: "#",
      icon: <Icon iconName="payment" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.promotionalTools"),
      href: "/bonus/list",
      icon: <Icon iconName="promotion" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.sportsbook"),
      href: "#",
      icon: <Icon iconName="bookOpen" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.userManagements"),
      href: "#",
      icon: <Icon iconName="userPen" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.tools"),
      href: "#",
      icon: <Icon iconName="settings" className="h-6 w-6 shrink-0" />,
      current: false,
    },
  ];
  const sidebarClassNames = cx(
    "h-full bg-gray-900 p-6 transition-all duration-500 ease-in-out absolute z-50",
    {
      "w-[85px]": navColapssed,
      "sm:w-1/2 md:w-1/3 lg:w-[250px]": !navColapssed,
    }
  );

  const contentClassNames = cx("flex-grow flex flex-col relative ml-[85px]");

  const anchorClassName = cx(
    "flex flex-row items-center group gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-400 hover:text-white hover:bg-primary whitespace-nowrap",
    {
      "w-[50px] justify-center": navColapssed,
    }
  );

  const disclosureButtonClassNames = cx(
    "flex flex-row items-center group gap-x-3 rounded-md p-2 text-left text-sm font-normal leading-6 text-gray-400 hover:text-white hover:bg-primary justify-center",
    {
      "w-[50px] justify-center": navColapssed,
      "w-full justify-center": !navColapssed,
    }
  );
  const generateBreadcrumbs = () => {
    if (pathname === "/") {
      return [{ name: "Home", href: "/", current: true }];
    }

    let pathnames = pathname.split("/").filter((x) => x);

    if (pathnames.length === 3) {
      pathnames = pathnames.slice(0, 2);
    }

    return pathnames.map((name, index) => {
      const href = `/${pathnames.slice(0, index + 1).join("/")}`;
      const isLast = index === pathnames.length - 1;

      return {
        name: t(`menuItems.${name}`) || name,
        href,
        current: isLast,
      };
    });
  };

  const breadcrumbs = generateBreadcrumbs();
  return (
    <div className="flex bg-gray-100 h-screen w-screen">
      <div
        ref={sidebarRef}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        className={sidebarClassNames}
        role="navigation"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setNavCollapsed(!navColapssed);
          }
        }}
      >
        <div className="mb-10 ">
          {navColapssed ? (
            <Icon
              iconName="logo"
              className="shrink-0"
              svgProps={{ width: 39, height: 38 }}
            />
          ) : (
            <img src={logo} style={{ width: "161px", height: "39px" }} />
          )}
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item, index) => (
                  <li key={item.name}>
                    {!item.children ? (
                      <Link to={item.href ?? "#"} className={anchorClassName}>
                        {item.icon}
                        {!navColapssed && item.name}
                      </Link>
                    ) : (
                      <Disclosure as="div">
                        <DisclosureButton
                          className={disclosureButtonClassNames}
                          onClick={() => handleDisclosureToggle(index)}
                        >
                          {item.icon}
                          {!navColapssed && item.name}
                          {!navColapssed && (
                            <Icon
                              iconName="rightArrow"
                              svgProps={{ width: 18, height: 18 }}
                              className="ml-auto h-5 w-5 shrink-0 only:group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                            />
                          )}
                        </DisclosureButton>
                        {openIndex === index && !navColapssed && (
                          <DisclosurePanel as="ul" className="mt-1 px-2">
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                <Link
                                  to={subItem.href ?? "/"}
                                  className="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-400 hover:text-white font-normal"
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                          </DisclosurePanel>
                        )}
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div className={contentClassNames}>
        <div className="shadow-lg z-10 bg-white h-16 border-b-2 flex pl-6 justify-between">
          <BreadCrumb pages={breadcrumbs} />
          <div className="flex items-center">
            <LanguageSelect />
            <UserProfile />
          </div>
        </div>
        {pathname === "/players/list" && <QuickAccess />}
        <div className="h-[calc(100vh-65px)]  overflow-auto">{children}</div>
      </div>
    </div>
  );
}
