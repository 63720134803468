import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { checkAuthStatus } from "store/auth/authenticationSlice";
import Router from "./pages/routes";
import Login from "pages/auth/login";
import Casino from "pages/site/casino";

function App() {
  const dispatch = useAppDispatch();
  //@ts-ignore
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);
  const token = localStorage.getItem("token");

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  // DASHBOARD
  if (loading) {
    return null;
  }
  return <>{isAuthenticated && token ? <Router /> : <Login />}</>;

  // CASINO
  /* return <Casino />; */
}

export default App;
