const API_BASE_URL = "https://stadmin.pixupplay.com/api/";
// const API_BASE_URL = "http://localhost:8000/";
export const COUNTRY_API_URLS = {
  GET_COUNTRIES: `${API_BASE_URL}countries`,
};

export const MARKETING_CODES_API_URLS = {
  GET_MARKETING_CODES: `${API_BASE_URL}marketing-codes`,
};

export const CURRENCY_API_URLS = {
  GET_CURRENCY: `${API_BASE_URL}currencies`,
};

export const REVENUE_SCHEMES_API_URLS = {
  GET_REVENUE_SCHEMES: `${API_BASE_URL}revenues`,
};

export const LANGUAGES_API_URLS = {
  GET_LANGUAGES: `${API_BASE_URL}languages`,
};

export const PLAYERS_API_URLS = {
  GET_PLAYERS: `${API_BASE_URL}players`,
  GET_PLAYER_BY_ID: (id: string) => `${API_BASE_URL}/players/getById/${id}`,
};

export const BONUSES_API_URLS = {
  GET_BONUSES: `${API_BASE_URL}bonuses`,
};
export const PLAYER_WALLET_API_URLS = {
  GET_PLAYER_WALLET: (id: string) => `${API_BASE_URL}players/wallet/${id}`,
};

export const DEPOSIT_API_URLS = {
  DEPOSIT: (id: string) => ({
    url: `${API_BASE_URL}wallets/${id}/deposit`,
  }),
};

export const WITHDRAW_API_URLS = {
  WITHDRAW: (id: string) => ({
    url: `${API_BASE_URL}wallets/${id}/withdraw`,
  }),
};
