import React from "react";
export interface Currency {
  symbol: string;
  shortCut: string;
  fixedValueCount: number;
  total: number;
  _id: string;
}

export interface Wallet {
  walletCategory: string;
  _id: string;
  playerId: string;
  currency: Currency;
  total: number;
  id: number;
}

export interface Player {
  username: string;
  email: string;
  isValidated: boolean;
  isRegistered: boolean;
  languageId: string | null;
  addressLine: string;
  registerDate: string;
  name: string;
  surname: string;
  postalCode: string;
  location: string;
  brand: string;
  countryId: string | null;
  landline: string;
  marketingCodeId: string;
  revenueId: string;
  isValidate: boolean;
  wallet: Wallet[];
  id: string;
  referralNumber: string;
}
export const initPlayer = {
  username: "",
  email: "",
  isValidated: false,
  isRegistered: false,
  languageId: "",
  addressLine: "",
  registerDate: "",
  name: "",
  surname: "",
  postalCode: "",
  location: "Serbia, Europe",
  referralNumber: "48473829",
  brand: "",
  countryId: "",
  landline: "",
  marketingCodeId: "",
  revenueId: "",
  isValidate: false,
  wallet: [],
  id: "",
};
interface TabContextType {
  walletData: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  player: Player;
}

export const TabContext = React.createContext<TabContextType>({
  activeTab: "overview",
  walletData: [],
  player: initPlayer,

  setActiveTab: () => {},
});
