import PInput from "@components/core-components/input";
import { useState } from "react";
import Icon from "@components/core-components/icon";
import { useBaseQuery } from "api/core/useBaseQuery";
import {
  COUNTRY_API_URLS,
  CURRENCY_API_URLS,
  MARKETING_CODES_API_URLS,
} from "config/apiUrls";
import AddBonusModal from "./AddBonusModal";

interface QuickFiltersProp {
  closeFilters: boolean;
  handleExportCSV: () => void;
  searchText: string;
  handleSearch: (e: any) => void;
  setCloseFilters: (value: boolean) => void;
}

type Region = {
  id: number;
  name: string;
};

type MarketingCode = {
  id: number;
  code: string;
  affiliate_id: number;
  brand_id: number;
};

const mapOptions = (data: any, labelTitle: string) => {
  return data?.map((item: any) => ({
    value: item.id.toString(),
    label: item[labelTitle],
  }));
};

function FilterHeader({
  setCloseFilters,
  closeFilters,
  searchText,
  handleSearch,
  handleExportCSV,
}: Readonly<QuickFiltersProp>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: countries } = useBaseQuery<Region[]>({
    endpoint: COUNTRY_API_URLS.GET_COUNTRIES,
  });

  const { data: marketingCode } = useBaseQuery<MarketingCode[]>({
    endpoint: MARKETING_CODES_API_URLS.GET_MARKETING_CODES,
  });

  const { data: currency } = useBaseQuery<[]>({
    endpoint: CURRENCY_API_URLS.GET_CURRENCY,
  });

  return (
    <>
      <div className="flex flex-col gap-5 mt-0 mb-4 xxl:gap-6 xxl:flex-row justify-between">
        <div className="flex  gap-4 ">
          <button
            onClick={() => setCloseFilters(!closeFilters)}
            className="flex items-center justify-between h-10 w-40 bg-primary text-white rounded-lg hover:bg-primary-hover transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 font-semibold text-sm"
          >
            <span className="flex items-center p-[15px] space-x-1 justify-center w-full ">
              {" "}
              <Icon
                iconName="filter"
                svgProps={{
                  width: "21px",
                  height: "21px",
                }}
              />
              <span className="text-[13px]">
                {!closeFilters ? "Open Filters" : "Close Filters"}
              </span>
            </span>
            <div className="flex items-center justify-center w-8 h-10 bg-indigo-600 rounded-r-lg">
              <Icon
                svgProps={{
                  width: "13px",
                }}
                iconName="whiteArrow"
                className={`transition-transform duration-300  ${
                  closeFilters ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
          </button>
          <PInput
            placeholder="Search name or username..."
            iconName="search"
            showIcon={true}
            value={searchText}
            id="filter-text-box"
            onChange={handleSearch}
            className="h-10 w-full md:w-80 sm:w-70 lg:w-96"
          />
        </div>

        <div className="flex space-x-6 m:flex-column">
          <button
            onClick={handleExportCSV}
            className={`flex h-10 items-center justify-center bg-primary-light w-[165px] text-primary px-5 py-4 rounded-lg transition-colors focus:outline-none hover:bg-primary-light-hover focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-[13px]`}
          >
            <Icon
              iconName="download"
              svgProps={{
                width: 21,
                height: 21,
              }}
            />
            <span>Download CSV</span>
          </button>

          <button
            disabled={true}
            className={`flex h-10 items-center justify-center bg-primary-light w-[165px] hover:bg-primary-light-hover text-primary px-5 py-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-[13px] disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            <Icon
              iconName="edit"
              svgProps={{
                width: 21,
                height: 21,
              }}
            />
            <span>Edit Columns</span>
          </button>

          <button
            onClick={() => setIsModalOpen(true)}
            className={`flex h-10 items-center justify-center bg-primary-light w-[165px] text-[13px] hover:bg-primary-light-hover text-primary px-5 py-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold `}
          >
            <Icon
              className="mb-1"
              iconName="bonus"
              svgProps={{
                width: 21,
                height: 21,
              }}
            />
            <span>Add Bonus</span>
          </button>
        </div>
        <AddBonusModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
}

export default FilterHeader;
