import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://stadmin.pixupplay.com/api/",
  // baseURL: "http://localhost:8000/",
});

export default axiosInstance;
// 67232725a5ac7041db6d9a4a
//admin id
