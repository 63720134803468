import { useState } from "react";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";

import { DateValueType } from "react-tailwindcss-datepicker";
import Icon from "@components/core-components/icon";
import { useBaseQuery } from "api/core/useBaseQuery";
import DateTimePicker from "@components/core-components/dateTimePicker";
import FilterTags from "./FilterTags";
import {
  COUNTRY_API_URLS,
  LANGUAGES_API_URLS,
  MARKETING_CODES_API_URLS,
  REVENUE_SCHEMES_API_URLS,
} from "config/apiUrls";
import dayjs from "dayjs";
type Region = {
  id: number;
  name: string;
};
type InputType = {
  placeholder?: string;
  value: string;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className: string;
};
type MarketingCode = {
  id: number;
  code: string;
  affiliate_id: number;
  brand_id: number;
};

function GridFilter({
  showSimpleFilters,
  filterParams,
  setShowSimpleFilters,
  onApplyFilters,
}: {
  filterParams?: { [key: string]: any };
  setShowSimpleFilters?: (value: boolean) => void;
  showSimpleFilters: boolean;
  onApplyFilters: (filters: any) => void;
}) {
  const [showTags, setShowTags] = useState(false);
  const [playerId, setPlayerId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [registrationDateOption, setRegistrationDateOption] =
    useState<string>("");
  const [lastLoginDateOption, setLastLoginDateOption] = useState<string>("");
  const [dateOfBirthOption, setDateOfBirthOption] = useState<string>("");
  const [selectedMobile, setSelectedMobile] = useState<string>("");
  const [selectedZipCode, setSelectedZipCode] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [selectedGender, setSelectedGender] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [birthdayDate, setBirthdayDate] = useState<any | null>(null);
  const [isValidated, setIsValidated] = useState<string>("");
  const [isRegistered, setIsRegistered] = useState<string>("");

  const removeFilter = (filterKey: string) => {
    if (filterParams) {
      const newFilters = { ...filterParams };

      delete newFilters[filterKey];

      if (filterKey === "registrationDate") {
        delete newFilters["registrationDateComp"];
        setRegistrationDate(null);
        setRegistrationDateOption("");
      }

      if (filterKey === "lastLogin") {
        delete newFilters["lastLoginComp"];
        setLastLoginDate(null);
        setLastLoginDateOption("");
      }
      if (filterKey === "dateOfBirth") {
        delete newFilters["dateOfBirthComp"];
        setBirthdayDate(null);
        setDateOfBirthOption("");
      }
      switch (filterKey) {
        case "id":
          setPlayerId("");
          break;
        case "email":
          setEmail("");
          break;
        case "mobile":
          setSelectedMobile("");
          break;
        case "zipCode":
          setSelectedZipCode("");
          break;
        case "country":
          setSelectedCountry(null);
          break;
        case "gender":
          setSelectedGender(null);
          break;

        case "isValidated":
          setIsValidated("");
          break;
        case "isRegistered":
          setIsRegistered("");
          break;
        case "revenueScheme":
          setSelectedOption3(null);
          break;
        case "marketingCode":
          setSelectedOption2(null);
          break;
        case "language":
          setSelectedOption(null);
          break;
        case "dateOfBirth":
          setBirthdayDate(null);
          break;
        case "zip":
          setSelectedZipCode("");
          break;
        default:
          break;
      }

      if (Object.keys(newFilters).length === 0) {
        setShowTags(false);
        onApplyFilters({});
        setShowSimpleFilters && setShowSimpleFilters(true);
      } else {
        onApplyFilters(newFilters);
      }
    }
  };

  const handleReset = () => {
    setPlayerId("");
    setEmail("");
    setSelectedCountry(null);
    setSelectedGender(null);
    setSelectedZipCode("");
    setDateOfBirthOption("");
    setSelectedMobile("");
    setRegistrationDate(null);
    setBirthdayDate(null);
    setFirstDepositDate(null);
    setVerificationDate(null);
    setIsValidated("");
    setSelectedOption(null);
    setSelectedOption2(null);
    setSelectedOption3(null);
    setIsRegistered("");
    onApplyFilters({});
    setLastLoginDate(null);
    setRegistrationDateOption("");
    setLastLoginDateOption("");
  };
  const handleApplyFilters = () => {
    setShowTags(true);
    setShowSimpleFilters && setShowSimpleFilters(false);

    const filters = {
      ...(birthdayDate && {
        dateOfBirth: dayjs(birthdayDate).format("YYYY-MM-DD"),
        dateOfBirthComp: dateOfBirthOption ? dateOfBirthOption : "gt",
      }),

      ...(registrationDate && {
        registrationDate: registrationDate,
        registrationDateComp: registrationDateOption
          ? registrationDateOption
          : "gt",
      }),
      ...(lastLoginDate && {
        lastLogin: lastLoginDate,
        lastLoginComp: lastLoginDateOption ? lastLoginDateOption : "gt",
      }),
      ...(selectedMobile && { mobile: selectedMobile }),
      ...(selectedOption3 && { revenueScheme: selectedOption3 }),
      ...(selectedOption2 && { marketingCode: selectedOption2 }),
      ...(selectedZipCode && { zip: selectedZipCode }),
      ...(selectedCountry && { country: selectedCountry }),
      ...(selectedOption && { language: selectedOption }),
      ...(playerId && { id: playerId }),
      ...(email && { email }),
      ...(selectedGender && { gender: selectedGender }),
      ...(isValidated && { isValidated }),
      ...(isRegistered && { isRegistered }),
    };

    onApplyFilters(filters);
  };

  const [selectedOption, setSelectedOption] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [selectedOption3, setSelectedOption3] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [registrationDate, setRegistrationDate] = useState<any>(null);
  const [lastLoginDate, setLastLoginDate] = useState<any>(null);

  const [firstDepositDate, setFirstDepositDate] =
    useState<DateValueType | null>(null);
  const [verificationDate, setVerificationDate] =
    useState<DateValueType | null>(null);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const { data: countryies } = useBaseQuery<Region[]>({
    endpoint: COUNTRY_API_URLS.GET_COUNTRIES,
  });
  const { data: marketingCode } = useBaseQuery<MarketingCode[]>({
    endpoint: MARKETING_CODES_API_URLS.GET_MARKETING_CODES,
  });
  const { data: languages } = useBaseQuery<Region[]>({
    endpoint: LANGUAGES_API_URLS.GET_LANGUAGES,
  });
  const { data: revenueData } = useBaseQuery<Region[]>({
    endpoint: REVENUE_SCHEMES_API_URLS.GET_REVENUE_SCHEMES,
  });

  const mapOptions = (data: any, labelTitle: string) => {
    return data?.map((item: any) => ({
      value: item._id.toString(),
      label: item[labelTitle],
    }));
  };

  const languagesOptions = mapOptions(languages, "name");
  const countryOptions = mapOptions(countryies, "name");
  const marketingCodeOptions = mapOptions(marketingCode, "code");
  const revenueSchemeOptions = mapOptions(revenueData, "name");
  const genderOptions = [
    { value: "W", label: "Female" },
    { value: "M", label: "Male" },
  ];

  const dateOptions = [
    { value: "eq", label: "Equal" },
    { value: "gt", label: "Greater Than" },
    { value: "lt", label: "Less Than" },
    { value: "lte", label: "Less Than or Equal" },
    { value: "gte", label: "Greater Than or Equal" },
  ];
  return (
    <>
      <div
        className={`transition-all w-full z-40 duration-500 ease-in-out transform ${
          showSimpleFilters
            ? "opacity-100 max-h-full pointer-events-auto"
            : "opacity-0 max-h-0 pointer-events-none"
        }`}
        style={{
          transitionProperty: "max-height, opacity, transform",
          maxHeight: showSimpleFilters ? "100%" : "0",
        }}
      >
        <div className="grid grid-cols-5 gap-3 mt-1  mb-0">
          <PInput
            placeholder="Player ID"
            value={playerId}
            onChange={(e) => {
              setPlayerId(e.target.value);
            }}
            className="w-full h-11"
          />
          <PInput
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="w-full h-11"
          />
          <BSelect
            options={Array.isArray(countryOptions) ? countryOptions : []}
            value={selectedCountry?.id || ""}
            onChange={(e: any) => {
              setSelectedCountry({
                id: e.target.value,
                name:
                  countryOptions.find(
                    (item: InputType) => item.value === e.target.value
                  )?.label || "",
              });
            }}
            className="w-full mb-3"
            placeholder="Country"
          />
          <BSelect
            options={[
              {
                value: "true",
                label: "True",
              },
              {
                value: "false",

                label: "False",
              },
            ]}
            value={isRegistered}
            onChange={(e: any) => setIsRegistered(e.target.value)}
            className="w-full mb-3"
            placeholder="Is Registered"
          />
          <BSelect
            options={genderOptions}
            value={selectedGender?.id || ""}
            onChange={(e: any) => {
              setSelectedGender({
                id: e.target.value,
                name:
                  genderOptions.find((item) => item.value === e.target.value)
                    ?.label || "",
              });
            }}
            className="w-full mb-3"
            placeholder="Gender"
          />
        </div>
        <div className="grid grid-cols-5 gap-3  ">
          <div>
            <DateTimePicker
              label="Date of Birth"
              withTime={false}
              limitFutureDays={true}
              value={birthdayDate}
              setValue={setBirthdayDate}
              options={dateOptions}
              selectValue={dateOfBirthOption}
              setSelectValue={setDateOfBirthOption}
            />
          </div>

          <div>
            <DateTimePicker
              limitFutureDays={true}
              label="Registration Date"
              value={registrationDate}
              setValue={(date: any) => {
                setRegistrationDate(date);
              }}
              options={dateOptions}
              selectValue={registrationDateOption}
              setSelectValue={setRegistrationDateOption}
            />
          </div>
          <div>
            <DateTimePicker
              label="Last Login Date"
              limitFutureDays={true}
              options={dateOptions}
              selectValue={lastLoginDateOption}
              setSelectValue={setLastLoginDateOption}
              value={lastLoginDate}
              setValue={setLastLoginDate}
            />
          </div>

          <BSelect
            options={[
              {
                value: "true",
                label: "True",
              },
              {
                value: "false",

                label: "False",
              },
            ]}
            value={isValidated}
            onChange={(e: any) => setIsValidated(e.target.value)}
            className="w-full mb-4"
            placeholder="Is Validated"
          />
        </div>
        <div
          className={` transition-all  duration-500 ease-in-out transform ${
            showAdvancedFilters
              ? "max-h-screen opacity-100 translate-y-0"
              : "max-h-0 opacity-0 overflow-hidden translate-y-2"
          }`}
          style={{
            transitionProperty: "max-height, opacity, transform",
            maxHeight: showAdvancedFilters ? "1000px" : "0",
          }}
        >
          <h1 className="text-md font-bold mt-1 mb-4">Advanced Filters</h1>

          <div className="grid grid-cols-5 gap-3 mt-4">
            <BSelect
              options={Array.isArray(languagesOptions) ? languagesOptions : []}
              value={selectedOption?.id || ""}
              onChange={(e: any) => {
                setSelectedOption({
                  id: e.target.value,
                  name:
                    languagesOptions.find(
                      (item: InputType) => item.value === e.target.value
                    )?.label || "",
                });
              }}
              className="w-full mb-4"
              placeholder="Language"
            />

            <PInput
              type="number"
              placeholder="Mobile"
              value={selectedMobile}
              onChange={(e) => {
                const cleanedValue = e.target.value.replace(/\+/g, "");
                setSelectedMobile(cleanedValue);
              }}
              className="w-full h-11"
            />
            <PInput
              placeholder="Zip-Code"
              value={selectedZipCode}
              onChange={(e) => {
                setSelectedZipCode(e.target.value);
              }}
              className="w-full h-11"
            />
            <BSelect
              options={
                Array.isArray(marketingCodeOptions) ? marketingCodeOptions : []
              }
              value={selectedOption2?.id || ""}
              onChange={(e: any) => {
                setSelectedOption2({
                  id: e.target.value,
                  name:
                    marketingCodeOptions.find(
                      (item: InputType) => item.value === e.target.value
                    )?.label || "",
                });
              }}
              className="w-full  h-11 mb-4"
              placeholder="Marketing Code"
            />
            <BSelect
              options={
                Array.isArray(revenueSchemeOptions) ? revenueSchemeOptions : []
              }
              value={selectedOption3?.id || ""}
              onChange={(e: any) => {
                setSelectedOption3({
                  id: e.target.value,
                  name:
                    revenueSchemeOptions.find(
                      (item: InputType) => item.value === e.target.value
                    )?.label || "",
                });
              }}
              className="w-full  h-11 mb-4"
              placeholder="Revenue Scheme"
            />
          </div>
        </div>
        <div
          className=" h-[42px] flex space-x-2 items-center text-[13px] justify-center bg-gray-200 text-[#4B5675] py-2 px-3 rounded cursor-pointer"
          onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
        >
          <Icon
            iconName={showAdvancedFilters ? "minus" : "plus"}
            svgProps={{ width: 18, height: 18 }}
            className={`mr-0  transition-transform transition-opacity duration-200 ease-in-out ${
              showAdvancedFilters
                ? "opacity-100 scale-100"
                : "opacity-75 scale-95"
            }`}
          />
          <p>Advanced Filters</p>
        </div>

        <div className="flex justify-end my-4 space-x-3">
          <button
            onClick={handleReset}
            className={`flex justify-center h-10 w-[139px] items-center bg-gray-300 text-black p-4 rounded-md hover:bg-gray-400 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            Reset
          </button>
          <button
            onClick={handleApplyFilters}
            className={`flex justify-center h-10 w-[139px] items-center bg-indigo-500 text-white p-4 rounded-md hover:bg-indigo-600 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            Apply
          </button>
        </div>
      </div>

      {setShowSimpleFilters && (
        <FilterTags
          filters={filterParams || []}
          removeFilter={removeFilter}
          reset={() => {
            setShowSimpleFilters && setShowSimpleFilters(true);
            setShowTags(false);
            handleReset();
          }}
          showTags={showTags}
        />
      )}
    </>
  );
}

export default GridFilter;
