import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "store/auth/authenticationSlice";

function UserProfile() {
  const { userId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isProfileVisible, setProfileVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setProfileVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const logout = () => {
    //clear cookies

    dispatch(logoutUser());
  };

  return (
    <>
      <a
        onClick={() => setProfileVisible(!isProfileVisible)}
        href="#"
        className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 relative cursor-pointer"
      >
        <img
          alt=""
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          className="h-8 w-8 rounded-full bg-gray-50"
        />
        <span className="sr-only">Your profile</span>
      </a>
      {isProfileVisible && (
        <div
          className="flex flex-col absolute w-[350px] top-[70px] right-8 shrink-0 rounded-[10px] bg-white shadow-md z-50"
          ref={ref}
        >
          <div className="h-[108px] flex flex-row items-center pl-6 border-b border-b-gray-300  w-full">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="h-[60px] w-[60px] rounded-full bg-gray-50  mr-4"
            />
            <div className="flex flex-col">
              <span className="text-heading-16 font-semibold text-gray-900">
                {userId}
              </span>
              <span className="text-body-reg-14 text-gray-500 font-regular">
                robert@gmail.com
              </span>
            </div>
          </div>

          <Link
            to="/profile/details"
            className="flex flex-row pl-6 items-center cursor-pointer h-16 border-b border-b-gray-300 w-full hover:bg-gray-200 text-body-reg-14 font-medium text-gray-900"
          >
            Profile Details
          </Link>

          <Link
            to="#"
            onClick={() => logout()}
            className="flex flex-row pl-6 items-center cursor-pointer h-16 border-b border-b-gray-300 w-full hover:bg-gray-200 text-body-reg-14 font-medium text-gray-900"
          >
            Logout
          </Link>
        </div>
      )}
    </>
  );
}

export default UserProfile;
