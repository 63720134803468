import PlayerProfile from "./PlayerProfile";

function Header() {
  return (
    <div>
      <PlayerProfile />
    </div>
  );
}

// pipeline test
export default Header;
