import React, { useContext, useEffect, useState } from "react";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";
import { SubmitHandler, useForm } from "react-hook-form";
import { TabContext } from "pages/players/details/components/TabContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { WITHDRAW_API_URLS } from "config/apiUrls";
import axios from "axios";
interface IFormInput {
  amount: number;
}
const schema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .min(1, "Amount must be greater than 0"),
});
const WithDrawal: React.FC = () => {
  const { walletData } = useContext(TabContext);

  const [selectedOption, setSelectedOption] = useState("EUR");
  const [balance, setBalance] = useState<number>(0);
  const [walletId, setWalletId] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const selectedWallet = walletData?.find(
      (wallet: any) => wallet.currency.shortCut === selectedOption
    );
    if (selectedWallet) {
      setBalance(selectedWallet.total);
      setWalletId(selectedWallet._id);
    }
  }, [selectedOption, walletData]);

  const options = walletData?.map((wallet: any) => ({
    value: wallet.currency.shortCut,
    label: wallet.currency.shortCut,
  }));

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const model = {
      amount: data.amount,
    };

    const { url } = WITHDRAW_API_URLS.WITHDRAW(walletId);

    try {
      const result = await axios.put(url, model);
      console.log(result.data);
    } catch (error) {
      console.error("Error making deposit:", error);
    }
  };

  const [inputValue, setInputValue] = useState("€4.416,00");

  return (
    <form className="flex flex-col pt-6 pb-9 mb-52 shadow-[0px_2px_6px_rgba(0,0,0,0.1)] bg-white">
      <main className="flex flex-col items-start px-6 w-full text-slate-600 max-md:px-5 max-md:max-w-full">
        <div className="h-[42px] flex flex-row items-center  w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Balance
          </span>
          <div className="h-full w-[437px] flex flex-row items-center">
            {/* <PInput
              type="number"
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
              className="h-full w-[250px] font-bold rounded-r-none"
              wrapperClassNames="h-full"
              placeholder="€5.000,00"
            /> */}
            <PInput
              disabled
              type="number"
              value={balance.toFixed()}
              className="h-full w-[250px] font-bold rounded-r-none"
              wrapperClassNames="h-full"
            />
            <div className="h-full w-[187px] font-bold rounded-l-none border-l-0 bg-gray-200 border rounded-r-md border-gray-400">
              <span className="h-full flex items-center justify-center text-gray-600 rounded-l-lg text-body-reg-12 font-normal">
                Max Withdrawal:{" "}
                <span className="font-semibold ml-1">€100.000,00 </span>
              </span>
            </div>
          </div>
        </div>
        <hr className="mt-1 border-gray-300 w-full max-w-[406px]" />
        <div className="h-[42px] flex flex-row items-center w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Player Wallet
          </span>
          <BSelect
            className="h-full w-[437px] "
            options={options}
            value={selectedOption}
            placeholder="Select an Option"
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        </div>
        <hr className="mt-1 mb-1 border-gray-300 w-full max-w-[406px]" />
        <div className="h-[42px] flex flex-row items-center  w-full max-w-[855px] justify-between">
          <span className="text-body-reg-14 font-medium text-gray-700 leading-none max-w-[300px] w-full">
            Amount
          </span>
          <PInput
            id="amount"
            type="number"
            register={register}
            className="h-full w-[437px] font-bold"
            wrapperClassNames="h-full"
            placeholder="Enter amount"
            // error={errors.amount}
          />
        </div>
        <hr className="mt-1 border-gray-300 w-full max-w-[406px]" />
        {errors.amount && (
          <span className="text-red-500 text-sm mt-1">
            {errors.amount.message}
          </span>
        )}

        <footer className="mt-5 w-full max-w-[855px] h-[42px] flex flex-row justify-end">
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-indigo-600 w-[139px] text-body-reg-13 h-full ml-3"
          >
            Withdrawal
          </button>
        </footer>
      </main>
    </form>
  );
};

export default WithDrawal;
